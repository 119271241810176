<template>
  <div class="full-all">
    <VinylViz
      :apBus="apBus"
      :song="song.name"
      :artist="song.artist"
    ></VinylViz>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import VinylViz from "./VinylViz.vue";
export default defineComponent({
  name: "ArtistHighlight",
  components: {
    VinylViz
  },
  props: ["apBus", "song"]
})
</script>