
import { defineComponent } from "vue";

import ChatMessage from "./ChatMessage.vue";
import ChatInput from "./ChatInput.vue";
import { ISignalMessage } from "@/views/Player/index.vue"

interface IUserChat {
  id: number;
  timestamp: number;
  username: string;
  msg: string;
  avatar?: string;
  pending?: boolean;
};

export default defineComponent({
  name: "Chat",
  components: {
    ChatMessage,
    ChatInput,
  },
  props: ["userCount"],
  data() {
    return {
      maxLength: 100,
      username: "",
      chats: Array<IUserChat>(),
    };
  },
  computed: {
    inputPlaceholder(): string {
      return this.username ? `how's the music, ${this.username}?` : "enter a username...";
    },
    chatsChronoSorted(): any[] {
      return [...this.chats].sort((a, b) => {
        return a.timestamp < b.timestamp
          ? 1
          : a.timestamp > b.timestamp
            ? -1
            : 0;
      });
    },
  },
  methods: {
    setUsername(val: any) {
      this.username = val.replace(/[^\w\s]/gi, '');
      localStorage.setItem('username', this.username);

      const welcomeMsg = {
        id: Date.now(),
        timestamp: Date.now(),
        username: '▂▃▅▇█ flowscape █▇▅▃▂',
        msg: `welcome to flowscape, "${this.username}"\n\nyou can change your username by typing:\n "/username <username>"\n`,
        avatar: "logo.png"
      };

      this.chats.push(welcomeMsg);
    },
    updateUsername(val: any) {
      this.username = val.replace(/[^\w\s]/gi, '');
      localStorage.setItem('username', this.username);

      const welcomeMsg = {
        id: Date.now(),
        timestamp: Date.now(),
        username: '▂▃▅▇█ flowscape █▇▅▃▂',
        msg: `your username is now "${this.username}"\n\n...they'll never even know ( ͡° ͜ʖ ͡°)\n`,
        avatar: "logo.png"
      };

      this.chats.push(welcomeMsg);
    },
    sendMsg(msg: any) {
      if (!this.username) {
        this.setUsername(msg);
        return;
      } else if (msg.includes("/username")) {
        this.updateUsername(msg.replace('/username ', ''));
        return;
      }
      if (this.chats.length > this.maxLength) {
        this.chats.shift();
      }
      this.chats.push({
        id: Date.now(),
        timestamp: Date.now(),
        msg: msg,
        username: this.username,
        pending: true
      });
      this.$emit("send", { username: this.username, message: msg });
    },
    recvMsg(msg: ISignalMessage) {
      // if msg recv'd is one of our pending messages, resolve
      let pend = this.chats.filter(cm => cm.pending).find(m => m.msg == msg.data);
      if (pend) pend.pending = false
      else {
        if (this.chats.length > this.maxLength) {
          this.chats.shift();
        }
        this.chats.push({
          id: Date.now(),
          timestamp: Date.now(),
          msg: msg.data,
          username: msg.user
        })
      }
    }
  },
  mounted() {
    let un = localStorage.getItem("username");
    if (un) this.setUsername(un);
  }
});
