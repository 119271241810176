import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import Player from "@/views/Player/index.vue";
import PressKit from "@/views/PressKit/index.vue";
import Err404 from "@/views/Errors/404.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Player",
    component: Player
  },
  {
    path: "/about",
    name: "About",
    component: PressKit
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404"
  },
  {
    path: "/404",
    component: Err404
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;