<template>
  <div id="dev-control-panel">
    <Analyzer
      :ap="audioPipeline"
      :apBus="apBus"
    ></Analyzer>
    <div
      class="control-row"
      v-for="key in Object.keys(devControls)"
      :key="key"
    >
      <label>{{key}}</label>
      <input
        type="number"
        :value="devControls[key].val"
        :min="devControls[key].min"
        :max="devControls[key].max"
        :step="devControls[key].step"
        @input="updateAudioParam($event.target.value, key)"
      >
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Analyzer from "./Analyzer.vue";
export default defineComponent({
  name: "DevControlPanel",
  components: {
    Analyzer,
  },
  props: ["audioPipeline", "apBus"],
  data(): { devControls: any } {
    return {
      devControls: {
        mainGainDry: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        mainGainWet: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        streamGainDry: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        streamGainWet: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        effectsGain: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
      },
    }
  },
  methods: {
    updateAudioParam(newVal: number, k: string) {
      let control = this.devControls[k];
      control.val = +newVal;
      if (!control) return;
      this.apBus.emit("setParam", { paramName: k, paramVal: +control.val });
    },
  }
});
</script>
<style scoped>
.control-row {
  display: flex;
  flex-flow: row;
  padding: 0.5em;
  width: 100%;
  justify-content: flex-end;
}
.control-row:nth-child(1) {
  flex: 1 1 0;
  padding: 1em;
}
#dev-control-panel {
  position: absolute;
  top: 20vh;
  right: 0;
  padding-right: 2em;
  width: 20vw;
  color: white;
  display: flex;
  flex-flow: column;
  justify-content: end;
  z-index: 100;
  background-color: var(--primary-color);
}
#dev-control-panel input {
  width: 80px;
  margin: 1em;
}
#dev-control-panel label {
  margin: 1em 0;
}
</style>