
import { defineComponent } from "vue";
import Analyzer from "./Analyzer.vue";
export default defineComponent({
  name: "DevControlPanel",
  components: {
    Analyzer,
  },
  props: ["audioPipeline", "apBus"],
  data(): { devControls: any } {
    return {
      devControls: {
        mainGainDry: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        mainGainWet: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        streamGainDry: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        streamGainWet: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        effectsGain: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
      },
    }
  },
  methods: {
    updateAudioParam(newVal: number, k: string) {
      let control = this.devControls[k];
      control.val = +newVal;
      if (!control) return;
      this.apBus.emit("setParam", { paramName: k, paramVal: +control.val });
    },
  }
});
