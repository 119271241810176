<template>
  <div id="press-kit">
    <div
      class="section"
      v-for="(section, i) of $options.copy"
      :key="i"
      tabindex="0"
    >
      <div class="section-bg"></div>
      <div class="section-title">{{section.title}}</div>
      <p
        class="section-body"
        v-for="(p, j) of section.body"
        :key="j"
      >{{p}}</p>
      <div class="next-section-wrap">
        <div
          class="next-section"
          @click="nextSection(i)"
          title="next"
        ></div>
      </div>
    </div>
    <router-link
      to="/"
      class="back-nav"
      title="back home"
    >
      {{"HO<M>E"}}
    </router-link>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import sections from "./sections.json"; // TODO: pull from s3 l8r for updating without code change
export default defineComponent({
  name: "PressKit",
  copy: sections, // custom option $options.copy
  data() {
    return {}
  },
  methods: {
    nextSection(i: number) {
      const n = i < this.$options.copy.length - 1 ? i + 1 : 0;
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      (document.getElementById('app') as HTMLElement).scrollTo(0, n * vh / 100 * 88);
      //window.scrollTo(0, n * vh);
    }
  }
})
</script>
<style>
#press-kit {
  width: 100vw;
  color: white;
  font: 1.3em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(
        90deg,
        var(--primary-color) calc(13px - 2px),
        transparent 1%
      )
      center,
    linear-gradient(var(--primary-color) calc(13px - 2px), transparent 1%)
      center,
    var(--secondary-dark-color);
  background-size: 13px 13px;
  transition: 0.5s all ease-in-out;
  animation: gradient 720s ease infinite;
}
.back-nav {
  position: fixed;
  width: 5vw;
  text-align: center;
  top: 5px;
  left: 0px;
  font-size: calc(1em + 0.2vw);
  font-family: var(--styled-font-family);
  color: var(--accent-color);
  cursor: pointer;
  transition: 0.1s all;
  z-index: 3;
}
.back-nav:hover {
  text-shadow: 0px 0px 6px var(--accent-color);
  transform: scale(1.1);
}
.section {
  width: 90vw;
  height: 85vh;
  margin: 2.5vh;
  display: flex;
  position: relative;
  flex-direction: column;
}
.section-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--primary-color);
  clip-path: polygon(100% 0, 0 0, 0 80%, 50% 127.5vh, 100% 100%);
}

.section-title {
  z-index: 2;
  margin: 0.25em 1em;
  font-size: calc(1em + 0.75vw);
  font-family: var(--styled-font-family);
  letter-spacing: 0.15em;
  text-shadow: 10px 0px 20px var(--accent-color);
}
.section-body {
  z-index: 2;
  width: 50%;
  margin-left: 4em;
  font-size: calc(1em + 0.5vw);
}
.section:nth-child(2) > * {
  align-self: flex-end;
}
.section:nth-child(2) > .section-body {
  margin-right: 4em;
}
.section:nth-child(2) > .section-bg {
  clip-path: polygon(100% 0, 0 0, 0 100%, 50% 127.5vh, 100% 80%);
}
.next-section-wrap {
  filter: drop-shadow(0px 0px 9px var(--secondary-color));
  z-index: 2;

  position: absolute;
  left: 0;
  bottom: 0;
  width: 24vh;
  height: 16vh;
  transform: scale(1);
  animation: clickit 2s infinite;
}
.next-section {
  cursor: pointer;
  background-color: var(--secondary-color);
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 0 100%, 100% 100%);
}
.section:nth-child(2) > .next-section-wrap {
  left: calc(100% - 24vh);
}
.section:nth-child(2) > .next-section-wrap > .next-section {
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
}
@keyframes clickit {
  0% {
    transform: scale(0.98);
    filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0));
  }

  70% {
    transform: scale(1);
    filter: drop-shadow(0px 0px 9px var(--secondary-color));
  }

  100% {
    transform: scale(0.98);
    filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0));
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  33% {
    background-position: 0% -200%;
  }
  66% {
    background-position: -200% -400%;
  }
  100% {
    background-position: 0% 0%;
  }
}
</style>