<template>
  <svg
    ref="svg"
    :height="radius * 2"
    :width="radius * 2"
    opacity=".75"
  >
    <circle
      stroke="var(--accent-color)"
      fill="transparent"
      :stroke-dasharray="circumference + ' ' + circumference"
      :style="{ strokeDashoffset }"
      :stroke-width="stroke"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
  </svg>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "ProgressRing",
  props: {
    radius: Number,
    progress: Number,
    stroke: Number,
  },
  data() {
    return {};
  },
  computed: {
    normalizedRadius() {
      return this.radius - this.stroke * 2;
    },
    circumference() {
      return this.normalizedRadius * 2 * Math.PI;
    },
    strokeDashoffset() {
      return this.circumference - (this.progress / 100) * this.circumference;
    },
  },
});
</script>
