<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg4163"
    height="150px"
    width="10em"
    transform="scale(1.5, 1.5)"
  >
    <defs id="defs4165" />
    <metadata id="metadata4168">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g
      id="layer1"
      transform="translate(-18, -558)"
    >
      <path
        transform="matrix(0.2519826,0,0,0.28846066,370.16445,464.02087)"
        d="M 600.54465,708.87799 410.01279,707.82961 506.18664,543.34837 Z"
        id="path4721"
        style="opacity:1;fill:#ffffc9;fill-opacity:1;stroke:none;stroke-width:3.5999999;stroke-linecap:square;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
      />
      <path
        transform="matrix(0.17643264,0,0,0.21446726,408.47874,513.26279)"
        d="M 600.54465,708.87799 410.01279,707.82961 506.18664,543.34837 Z"
        id="path4721-2"
        style="opacity:1;fill:#000036;fill-opacity:1;stroke:none;stroke-width:3.5999999;stroke-linecap:square;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
      />
      <path
        id="path4758-9"
        d="m 486.18971,644.82294 c 1.53684,1.28702 3.47564,5.03012 6.54437,5.23892 3.69712,0.25153 5.38365,-5.11649 9.0937,-5.15661 3.66697,-0.0397 6.43561,2.77378 9.15573,4.9005"
        style="fill:none;fill-rule:evenodd;stroke:#ffffc9;stroke-width:2.29292798;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
      />
      <path
        id="path4758-9-0"
        d="m 491.74513,636.91184 c 0.74409,0.70985 1.92297,3.29444 3.62084,3.43118 2.04553,0.16473 2.97863,-3.35096 5.0313,-3.37726 2.02884,-0.0258 3.90008,1.85272 5.06567,3.2095"
        style="fill:none;fill-rule:evenodd;stroke:#ffffc9;stroke-width:1.62019467;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
      />
      <path
        id="path4758-9-4"
        d="m 481.63024,653.43078 c 0.30772,2.02934 5.22201,7.38663 9.83262,7.69321 5.55479,0.36939 8.46578,-7.02732 13.66285,-7.57237 3.01344,-0.31603 5.43178,2.27018 8.68404,2.64914"
        style="fill:none;fill-rule:evenodd;stroke:#ffffc9;stroke-width:2.46187735;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
      />
    </g>
    <g
      id="layer2"
      transform="translate(0, 100)"
    >
      <text
        id="text4711"
        y="0"
        x="64.432159"
        style="white-space:pre;font-size:40px;line-height:125%;font-family:'Nunito Sans',sans-serif;fill:#ffffc9;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
        xml:space="preserve"
      >
        <tspan
          y="0"
          x="0"
          id="tspan4713"
        >F L O W S C &nbsp P E</tspan>
      </text>

    </g>
  </svg>
</template>