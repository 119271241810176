<template>
  <router-view />
  <!-- <MobilePlayer v-if="isMobile" />
  <Player v-else /> -->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Player from "./views/Player/index.vue";
import MobilePlayer from "./components/Player/MobilePlayer.vue"

export default defineComponent({
  name: "App",
  components: {
    Player,
    MobilePlayer
  },
  computed: {
    isMobile() {
      return document.body.clientWidth < 500 || /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
  }
});
</script>

<style>
:root {
  --primary-color: #000036;
  --secondary-color: #ffffc9;
  --secondary-dark-color: #8e8e70;
  --accent-color: #f2379a;
  --min-app-width: 500px;
  --primary-font-family: "Nunito Sans", sans-serif;
  --styled-font-family: RoadRage;
}
@font-face {
  font-family: RoadRage;
  src: url("/assets/Road_Rage.otf");
}
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");
.secondary-color {
  color: var(--secondary-color);
}
html {
  overflow: hidden;
  background-color: var(--primary-color);
}
#app {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: var(--min-app-width); */
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}
body {
  margin: 0;
  padding: 0;
}

.full-all {
  width: 100%;
  height: 100%;
}
</style>
