import {
  CreateAudioSourceController,
  IAudioSourceController
} from "./MESController";
import EventEmitter from "eventemitter3";
import { AudioAnalyzer } from "./AudioAnalyzer";

export interface IAudioEvents {
  "setParam": (obj: { paramName: string, paramVal: number | Float32Array}) => void;
  "audio-source-error": (e: ErrorEvent) => void;
  "audio-source-ended": () => void;
  "beat-detect": () => void;
}
export function CreateAudioPipeline(ebus: EventEmitter<IAudioEvents>): AudioPipeline {
  return new AudioPipeline(ebus);
}

export enum AudioPipelineState {
  PLAYING = "playing",
  SUSPENDED = "suspended",
  UNINITIALIZED = "uninitialized",
  READY = "ready"
}

/*
 * AudioPipeline - a wrapper around AudioContext that includes composing the audio nodes
 *  used throughout the application
 * GRAPH: AudioSource<->AudioEffects<->GainNode<->Analyzer<->Destination
 */
export class AudioPipeline {
  public ctx: AudioContext;

  // wrapper of main music stream audio source node
  public audioSourceController: IAudioSourceController | null = null;
  public analyzer: AudioAnalyzer;

  // events bus to emit and subscribe to
  private _ebus: EventEmitter<IAudioEvents>;

  // gain nodes
  private _mainDry: GainNode | null = null;
  private _mainWet: GainNode | null = null;
  private _dry1: GainNode | null = null;
  private _wet1: GainNode | null = null;
  private _wet2: GainNode | null = null;
  private _audioSrcDry: GainNode | null = null;
  private _audioSrcWet: GainNode | null = null;

  // effects nodes
  private _lpFilter: BiquadFilterNode | null = null;
  private _waveshaper: WaveShaperNode | null = null;
  private _reverb: ConvolverNode | null = null;
  private _compressor: DynamicsCompressorNode | null = null;

  constructor(ebus: EventEmitter<IAudioEvents>) {
    this._ebus = ebus;
    this.ctx = new AudioContext();
    this.analyzer = new AudioAnalyzer(this.ctx, this._ebus);
  }

  public getState(): AudioPipelineState {
    if(!this.audioSourceController) return AudioPipelineState.UNINITIALIZED
    else if(this.ctx.state === 'running') return AudioPipelineState.READY
    else if(this.audioSourceController.isPlaying()) return AudioPipelineState.PLAYING
    else return AudioPipelineState.SUSPENDED
  }

  public resume(): void {
    if(!(this.ctx && this.audioSourceController)) return;

    this.ctx.resume();
  }

  public destroy(): void {
    this._compressor?.disconnect();
    this._mainDry?.disconnect();
    this._mainWet?.disconnect();
    this._dry1?.disconnect();
    this._wet1?.disconnect();
    this._wet2?.disconnect();
    this._lpFilter?.disconnect();
    this._waveshaper?.disconnect();
    this._reverb?.disconnect();
    this._audioSrcDry?.disconnect();
    this._audioSrcWet?.disconnect();
    this.audioSourceController?.audioSourceNode.disconnect();
    this.audioSourceController?.stop();
    this.audioSourceController = null;
    this.ctx.close();

    this.unbindEvents();
  }

  public setup(): boolean {
    if(this.audioSourceController != null) return true; // already setup dawg 
    if(!this.ctx){
      this.ctx = new AudioContext();
    }

    let c = this.ctx;

    this.audioSourceController = CreateAudioSourceController(c, this._ebus);
    // create gains
    this._mainDry = c.createGain();
    this._mainWet = c.createGain();
    this._mainWet.gain.value = 0;
    this._dry1 = c.createGain();
    this._wet1 = c.createGain();
    this._wet2 = c.createGain();
    this._audioSrcDry = c.createGain();
    this._audioSrcWet = c.createGain();

    // create effects
    this._lpFilter = c.createBiquadFilter();
    this._waveshaper = c.createWaveShaper();
    this._reverb = c.createConvolver();
    this._compressor = c.createDynamicsCompressor();
    
    // connect all the things
    this.analyzer.node.connect(this.ctx.destination);
    this._compressor.connect(this.analyzer.node);
    this._mainDry.connect(this._compressor);
    this._mainWet.connect(this._compressor);
    this._reverb.connect(this._mainWet);
    this._wet2.connect(this._reverb);
    this._lpFilter.connect(this._mainWet);
    this._lpFilter.connect(this._mainDry);
    this._dry1.connect(this._mainDry);
    this._waveshaper.connect(this._lpFilter);
    this._wet1.connect(this._waveshaper);

    // source connections
    this._audioSrcDry.connect(this._dry1);
    this._audioSrcWet.connect(this._wet1);
    this.audioSourceController.audioSourceNode.connect(this._audioSrcDry);
    this.audioSourceController.audioSourceNode.connect(this._audioSrcWet);

    
    // defaults
    this._mainDry.gain.value = 0.5;
    this._dry1.gain.value = 0.5;
    // this._audioSrcDry.gain.value = 0.5;
    this._mainWet.gain.value = 0;
    this._audioSrcWet.gain.value = 0;

    this.analyzer.setup();
    this._bindEvents();
    
    return this.ctx.state === "suspended";
  }

  private unbindEvents(){
    this._ebus.off("setParam", this._setParamValue, this);
  }
  private _bindEvents(){
    this._ebus.on("setParam", this._setParamValue, this);
  }
  
  private _setParamValue({paramName, paramVal}: {paramName: string, paramVal: number | Float32Array }): void {
    if(typeof paramVal != "number"){
      if(paramName == "waveshape") this._setWaveshapeCurve(paramVal);
    } else {
    switch(paramName){
      // case 'mainGain':
      //   this._setGainValue(this._mainDry, paramVal);
      //   this._setGainValue(this._mainWet, paramVal);
      //   break;
      case 'mainGainDry':
        this._setGainValue(this._mainDry, paramVal);
        break;
      case 'mainGainWet':
        this._setGainValue(this._mainWet, paramVal);
        break;
      case 'streamGain':
        this._setGainValue(this._audioSrcDry, paramVal);
        this._setGainValue(this._mainDry, paramVal);
        break;
      case 'streamGainDry':
        this._setGainValue(this._audioSrcDry, paramVal);
        break;
      case 'streamGainWet':
        this._setGainValue(this._audioSrcWet, paramVal);
        break;
      case 'effectsGain': // waveshaper, filter, and reverb gain
        this._setGainValue(this._wet2, paramVal);
        break;
      case 'lpFreq': // range: [0, nyquistF (~64k?)]
        if(this._lpFilter) this._lpFilter.frequency.value = paramVal;
        break;
      case 'lpQ': // range: [-770, 770] although testing looks like [0, 50]?
        if(this._lpFilter) this._lpFilter.Q.value = paramVal;
        break;
      case 'lpDetune': // range: [-770, 770] although testing looks like [0, 50]?
        if(this._lpFilter) this._lpFilter.detune.value = paramVal;
        break;
      default:
        console.warn(`Could not find AudioPipeline param: ${paramName}`);
        break;  
    }
    }
  }

  public isPlaying(): boolean {
    return !!(this.ctx.state === 'running' && this.audioSourceController && this.audioSourceController.isPlaying());
  }

  private _setGainValue(g: GainNode | null, val: number){
    if(g) g.gain.value = val;
  }

  private _setWaveshapeCurve(val: Float32Array): void {
    if(this._waveshaper) this._waveshaper.curve = val;
  }
}
