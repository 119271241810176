import EventEmitter from "eventemitter3";
import { IAudioEvents } from './AudioPipeline';

export interface IAudioSourceController {
  audioSourceNode: AudioNode;
  getAudioContext(): AudioContext;
  connectSource(src: string): void;
  play(): void;
  stop(): void;
  getTimeStats(): Error | { currentTime: Number; duration: Number };
  isPlaying(): boolean;
}

/* Factory function for creating an IAudioSourceController, for now we only use a MESController */
export function CreateAudioSourceController(
  ctx: AudioContext,
  ebus: EventEmitter<IAudioEvents>,
  src?: string
): IAudioSourceController {
  if (!src)
    src = "";//"data:audio/wav;base64,UklGRjIAAABXQVZFZm10IBIAAAABAAEAQB8AAEAfAAABAAgAAABmYWN0BAAAAAAAAABkYXRhAAAAAA==";

  // extend as needed to do logic and return different kinds of audio sources in future
  return new MESController(ctx, ebus, src);
}

/*
 *  MESController is a utility wrapper for creating and managing a MediaElementAudioSourceNode
 *
 *  params:
 *    ctx (AudioContext): audio context used to create audio node
 *    ebus (EventEmitter): event bus to emit and subscribe to
 *    src (string) (optional):
 *
 */

export class MESController implements IAudioSourceController {
  public audioSourceNode: MediaElementAudioSourceNode;
  private _ctx: AudioContext;
  private _ebus: EventEmitter<IAudioEvents>;
  private _a: HTMLAudioElement | null;

  // if set, will attempt to prefetch and pause playback
  private _prefetchSrc: string = "";

  // state ish
  private _isPlaying: boolean = false;

  constructor(ctx: AudioContext, ebus: EventEmitter<IAudioEvents>, prefetchSrc?: string) {
    this._ctx = ctx;
    this._ebus = ebus;

    this._a = new Audio();
    this._a.crossOrigin = "anonymous"
    this.audioSourceNode = ctx.createMediaElementSource(this._a);
    if (prefetchSrc) {
      this._prefetchSrc = prefetchSrc;
      this.connectSource(prefetchSrc);
    }
  }

  public connectSource(src: string): void {
    if(!this._a){
      this._a = new Audio();
      // emit new a so pipeline can connect it?
    }
    this._a.src = src;
    this._a.load();
  }

  public play(): void {
    if (this._a && !this._isPlaying) {
      this._a.play();
      this._isPlaying = true;
    } else {
      // emit error?
    }
  }

  public stop(): void {
    if (this._a) {
      this._a.pause();
      this._isPlaying = false;
    }
  }

  public getAudioContext(): AudioContext {
    return this._ctx;
  }

  public getTimeStats(): Error | { currentTime: Number; duration: Number } {
    if (!this._a) throw Error("AudioSource not created!");
    return {
      currentTime: this._a.currentTime, // current playback time in seconds
      duration: this._a.duration // length in seconds
    };
  }

  public isPlaying(): boolean {
    return this._isPlaying;
  }
}
