
import { defineComponent } from "vue";
import MobileSplashHero from "./Splash/MobileSplashHero.vue";
import MeshViz from "./SocialEngagement/MeshViz.vue";
import VinylViz from "./ArtistHighlight/VinylViz.vue";
import SocialChat from "./SocialEngagement/Chat/index.vue";
import AppControls from "./AppControls/index.vue";
import Analyzer from "./debug/Analyzer.vue";
import {
  IAudioEvents,
  AudioPipeline,
  CreateAudioPipeline,
} from "./lib/AudioPipeline";
import EventEmitter from "eventemitter3";

interface IChatMessage {
  username: string;
  message: string;
}
interface PlayerComponentData {
  curtains: null;
  apBus: EventEmitter<IAudioEvents>;
  audioPipeline: AudioPipeline;
  audioAnalyzer: null;
  state: string;
  appName: string;
  devControls: any;
  showDev: boolean;
  ws: WebSocket
}
export default defineComponent({
  name: "MobilePlayer",
  components: {
    MobileSplashHero,
    VinylViz,
    MeshViz,
    SocialChat,
    AppControls,
    Analyzer,
  },
  props: {},
  data(): PlayerComponentData {
    let ebus = new EventEmitter<IAudioEvents>();
    let loc = window.location;
    let uri = loc.protocol === 'https:' ? 'wss:' : 'ws:';
    uri = `${uri}//${loc.host}/ws`;
    return {
      curtains: null,
      apBus: ebus, // audio pipeline bus
      audioPipeline: CreateAudioPipeline(ebus),
      audioAnalyzer: null,
      state: "splash", // splash, loading, loaded
      appName: "FLOWSCAPE",
      showDev: false,
      ws: new WebSocket(uri),
      devControls: {
        mainGainDry: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        mainGainWet: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        streamGainDry: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        streamGainWet: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        effectsGain: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
      },
    };
  },
  methods: {
    sendChatMsg(msg: IChatMessage) {
      if (this.ws.readyState != WebSocket.OPEN) return;
      this.ws.send(JSON.stringify(msg));
    },
    onLogoClick() {
      if (this.state === "splash") {
        this.loadPlayer();
      } else if (this.state === "loaded") {
        this.state = "splash";
        this.audioPipeline.ctx.suspend();
      }
    },
    loadPlayer() {
      this.state = "loading";
      // if audio context hasn't been setup yet, do setup
      if (this.audioPipeline.getState() == "uninitialized") {
        this.audioPipeline.setup();
      } else {
        // otherwise, resume play
        this.audioPipeline.resume();
      }
      setTimeout(() => {
        this.state = "loaded";
      }, 1000);
    },
    onResize() {
      //this.setTxtRotate();
    },
    keyHandler(e: KeyboardEvent) {
      if (e.key === "Enter") {
        if (this.state === "splash") this.onLogoClick();
      } else if (e.code === "Backquote") {
        this.showDev = !this.showDev;
      }
    },
    updateAudioParam(newVal: number, k: string) {
      let control = this.devControls[k];
      control.val = +newVal;
      if (!control) return;
      this.apBus.emit("setParam", { paramName: k, paramVal: +control.val });
    },
  },
  created() {
    this.ws.onopen = () => {
      console.log('ws connected');
    };
    this.ws.onmessage = (evt: MessageEvent<any>) => {
      console.log("🚀 ~ file: index.vue ~ line 230 ~ created ~ evt", evt);
      // msg = { evt.username, evt.msg ...etc}
      (this.$refs.chat as any).recvMsg(JSON.parse(evt.data));
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("keypress", this.keyHandler);
    //this.setTxtRotate();
  },
  unmounted() {
    this.audioPipeline.destroy();
  },
});
