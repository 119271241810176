<template>
  <div class="full-all">
    <MeshViz
      :ap="audioPipeline"
      :apBus="apBus"
    ></MeshViz>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MeshViz from "./MeshViz.vue";
export default defineComponent({
  name: "SocialEngagement",
  components: {
    MeshViz
  },
  props: ["audioPipeline", "apBus", "playerState", "userCount"],
  data() { return {} },
});
</script>