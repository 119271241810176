
import { defineComponent } from "vue";
import Chat from "@/components/Player/SocialEngagement/Chat/index.vue";
import { IChatMessage, ISignalMessage } from "@/views/Player/index.vue";
import AppControls from "@/components/Player/AppControls/index.vue";

export default defineComponent({
  name: "SidebarControls",
  components: {
    Chat,
    AppControls
  },
  props: ["audioPipeline", "apBus", "userCount"],
  data() {
    return {
      active: 'chat',
      volume: 50,
    };
  },
  methods: {
    itemClick(event: Event, type: string) {
      if (type === 'chat') {
        if (this.active === 'chat') {
          // chat already active and clicked again
          this.active = "";
        } else {
          // show chat and hide previous widget
          this.active = type;
        }
      } else {
        this.active = type
      }

    },
    sendChatMsg(msg: IChatMessage) {
      this.$emit('send', msg);
    },
    recvMsg(msg: ISignalMessage) {
      (this.$refs.chat as any).recvMsg(msg);
    },
    updateVolume(): void {
      this.volume = Math.min(100, Math.max(0, this.volume));
      if (!this.apBus) return;
      this.apBus.emit("setParam", {
        paramName: "streamGain",
        paramVal: this.volume / 100, // map to [0, 1]
      });
    },
    updateVolumeFromWheel(e: WheelEvent): void {
      this.volume = Math.min(100, Math.max(0, this.volume + e.deltaY * -0.05));
      if (!this.apBus) return;
      this.apBus.emit("setParam", {
        paramName: "streamGain",
        paramVal: this.volume / 100, // map to [0, 1]
      });
    },
  },
});
