import { createApp } from "vue";
import App from "./App.vue";
import router from './router'

const fsapp = createApp(App);

// inject theme object as a quasi-plugin
fsapp.use({
  install: (app: any, options: any) => {
    app.config.globalProperties.$theme = {
      primaryColor: "#000036",
      secondaryColor: "#ffffc9",
      secondaryDarkColor: "#8e8e70",
      accentColor: "#f2379a"
    }
  }
});

fsapp.use(router);

fsapp.mount("#app");