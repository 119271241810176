<template>
  <svg
    :viewBox="`0 0 ${width} ${height}`"
    class="analyzer"
  >
    <Meter
      :x="0"
      :width="width"
      :volume="normalizedVolume"
      :beatThreshold="beatThreshold"
    ></Meter>
  </svg>

</template>
<script lang="ts">
import { defineComponent } from "vue";
import Meter from "./Meter.vue";

export default defineComponent({
  name: "Analyzer",
  components: {
    Meter,
  },
  props: ["ap", "apBus"],
  data() {
    return {
      width: 300,
      height: 500,
    };
  },
  computed: {
    normalizedVolume(): number {
      return this.ap.analyzer.normalizedVolume;
    },
    beatThreshold(): number {
      return this.ap.analyzer.beatThreshold;
    },
  },
  mounted() {
    this.apBus.on("beat-detect", () => {
      this.onBeat();
    });
    this.height = 332;
  },
  unmounted() {
    this.apBus.off("beat-detect");
  },
  methods: {
    onBeat() {
      let el = this.$el;
      el.style.borderBottomColor = "white";
      setTimeout(() => {
        el.style.borderBottomColor = "transparent";
      }, 250);
      // TweenMax.to(el, 0.1, {
      //   borderBottomColor: "white",
      //   onComplete: function () {
      //     TweenMax.to(el, 0.25, {
      //       borderBottomColor: "transparent",
      //     });
      //   },
      // });
    },
  },
});
</script>
<style scoped>
.analyzer {
  border-bottom: 2px solid transparent;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50%;
}
</style>