<template>
  <div
    id="play-toggle"
    @click="togglePlay"
    :class="isActive ? 'paused' : ''"
  ></div>
</template>
<style scoped>
#play-toggle {
  position: absolute;
  cursor: pointer;
  width: 30px;
  height: 30px;
  left: 50%;
  top: 50%;
  margin-left: -13px;
  margin-top: -15px;
  opacity: 0.75;
  background: url("../../../assets/play_btn_secondary.png") no-repeat center
    center / contain;
}
#play-toggle.paused {
  background: url("../../../assets/pause_btn_secondary.png") no-repeat center
    center / contain;
  margin-left: -16px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "PlayToggle",
  props: ["isActive"],
  data() {
    return {};
  },
  methods: {
    togglePlay(): void {
      this.$emit("toggle");
    },
  },
});
</script>
