
import { defineComponent } from "vue";
import Player from "./views/Player/index.vue";
import MobilePlayer from "./components/Player/MobilePlayer.vue"

export default defineComponent({
  name: "App",
  components: {
    Player,
    MobilePlayer
  },
  computed: {
    isMobile() {
      return document.body.clientWidth < 500 || /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
  }
});
