
import { defineComponent } from "vue";
import sections from "./sections.json"; // TODO: pull from s3 l8r for updating without code change
export default defineComponent({
  name: "PressKit",
  copy: sections, // custom option $options.copy
  data() {
    return {}
  },
  methods: {
    nextSection(i: number) {
      const n = i < this.$options.copy.length - 1 ? i + 1 : 0;
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      (document.getElementById('app') as HTMLElement).scrollTo(0, n * vh / 100 * 88);
      //window.scrollTo(0, n * vh);
    }
  }
})
