<template>
  <div id="playback-controls">
    <!-- <div id="stream-info">
      <div id="artist-name">{{ streamInfo.artist }} </div>
      <div id="song-name">{{ streamInfo.name }} </div>
    </div> -->
    <!-- <div
      id="stream-playback"
      @wheel.passive="updateVolume"
    >
      <ProgressRing
        :radius="35"
        :progress="volume"
        :stroke="4"
      ></ProgressRing>
      <PlayToggle
        :isActive="isPlaying"
        @toggle="onPlayToggle"
      />
    </div>
    <div class="fx-knob"></div> -->
  </div>
</template>
<style scoped>
.fx-wrapper {
  position: relative;
}
.fx-label {
  position: absolute;
  margin-top: 40%;
  margin-left: 25%;
}
.fx-knob {
  width: 100px;
  height: 100px;
  flex: 0 0 auto;
  /* margin-left: 25%; */
}
#playback-controls {
  display: flex;
  flex-flow: column;
  justify-items: center;
  height: 100%;
  width: 100%;
}
#stream-playback {
  position: absolute;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
  margin-left: -35px;
  margin-top: -35px;
}
#stream-info {
  margin-top: 3px;
  align-self: center;
  text-align: center;
  color: var(--primary-color);
  font-weight: bolder;
  text-shadow: 9px 9px var(--secondary-color);
}
#artist-name {
  color: var(--accent-color);
}
#song-name {
  margin-top: 3px;
}
</style>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import ProgressRing from "./ProgressRing.vue";
import PlayToggle from "./PlayToggle.vue";
import { AudioPipeline } from "../lib/AudioPipeline";
import EventEmitter from "eventemitter3";
import { IStreamInfo } from "@/views/Player/index.vue";

//import SC from "soundcloud";
const SC: any = require("soundcloud");

interface AppControlsData {
  curtains: any;
  volume: number;
  lpFreq: number;
  lpDetune: number;
  lpQ: number;
  isPlaying: boolean;
}
export default defineComponent({
  name: "AppControls",
  components: {
    ProgressRing,
    PlayToggle,
  },
  props: {
    ap: {
      type: Object as PropType<AudioPipeline>,
      required: true,
    },
    apBus: {
      type: Object as PropType<EventEmitter>,
      required: true,
    },
    streamInfo: {
      type: Object as PropType<IStreamInfo>,
      required: true,
    }
  },
  data(): AppControlsData {
    return {
      curtains: null,
      volume: 50,
      lpFreq: 10000 / 350,
      lpDetune: 0,
      lpQ: 50,
      isPlaying: false,
    };
  },
  methods: {
    onPlayToggle(): void {
      if (this.isPlaying) {
        this.stop();
      } else {
        this.play();
      }
    },
    stop(): void {
      if (!this.ap) return;
      if (!this.ap.audioSourceController) return;
      this.ap.audioSourceController.stop();
      this.isPlaying = false;
    },
    play(): void {
      if (!this.ap) return;
      // handle failed autoplay
      if (this.ap.getState() === "suspended") {
        this.ap.resume();
      }
      this.isPlaying = true;
      // SC.resolve("https://soundcloud.com/user-963275205/jamie-xx-bbc-radio-1-essential-mix-2020-04-25").then(
      //   (obj: any) => {
      //     if (!this.ap || !this.ap.audioSourceController) return;
      //     SC.get(`/i1/tracks/${obj.id}/streams`).then((streams: any) => {
      //       if (!this.ap || !this.ap.audioSourceController) return;
      //       this.ap.audioSourceController.connectSource(
      //         streams.http_mp3_128_url
      //       );
      //       this.ap.audioSourceController.play();
      //     });
      //   }
      // );
      if (!this.ap || !this.ap.audioSourceController) return;
      let uri = `${window.location.protocol}//${window.location.host}/flow`;
      let testFile = require("@/assets/test.ogg"); // TODO: better local switch than this
      if (window.location.host.includes('localhost')) {
        uri = testFile;
      }
      this.ap.audioSourceController.connectSource(uri);
      this.ap.audioSourceController.play();
    },
    updateVolume(e: WheelEvent): void {
      this.volume = Math.min(100, Math.max(0, this.volume + e.deltaY * -0.05));
      if (!this.apBus) return;
      this.apBus.emit("setParam", {
        paramName: "streamGain",
        paramVal: this.volume / 100, // map to [0, 1]
      });
    },
    updateLPFreq(e: WheelEvent): void {
      this.lpFreq = Math.min(100, Math.max(0, this.lpFreq + e.deltaY * -0.025));
      if (!this.apBus) return;
      let v = this.lpFreq * 100; // remap to [0, nyquist]
      console.log("🚀 ~ file: index.vue ~ line 172 ~ updateLPFreq ~ v", v);
      this.apBus.emit("setParam", {
        paramName: "lpFreq",
        paramVal: v,
      });
    },
    updateLPDetune(e: WheelEvent): void {
      this.lpDetune = Math.min(
        100,
        Math.max(0, this.lpDetune + e.deltaY * -0.05)
      );
      if (!this.apBus) return;
      let v = this.lpDetune * 100 - 5000; // remap to [-153000, 153000]
      this.apBus.emit("setParam", {
        paramName: "lpDetune",
        paramVal: v,
      });
    },
    updateLPQ(e: WheelEvent): void {
      this.lpQ = Math.min(100, Math.max(0, this.lpQ + e.deltaY * -0.05));
      if (!this.apBus) return;
      let v = this.lpQ - 50; // remap to [-770, 770]
      this.apBus.emit("setParam", {
        paramName: "lpQ",
        paramVal: v,
      });
    },
  },
  mounted() {
    SC.initialize({
      client_id: "boDIr5bvOJeaKn2qiZGS3CR6ruDoAKS3",
    });
    this.isPlaying = this.ap.isPlaying();
    if (!this.isPlaying) this.play();
  },
});
</script>