<template>
  <div
    id="player"
    :class="['mobile', state]"
  >
    <div
      id="dev-control-panel"
      v-if="showDev"
    >
      <Analyzer
        :ap="audioPipeline"
        :apBus="apBus"
      ></Analyzer>
      <div
        class="control-row"
        v-for="key in Object.keys(devControls)"
        :key="key"
      >
        <label>{{key}}</label>
        <input
          type="number"
          :value="devControls[key].val"
          :min="devControls[key].min"
          :max="devControls[key].max"
          :step="devControls[key].step"
          @input="updateAudioParam($event.target.value, key)"
        >
      </div>
    </div>
    <div
      id="player-top"
      ref="playerTop"
    >
      <div
        v-if="state === 'loaded'"
        class="logo-menu"
        @click="onLogoClick"
      ></div>
      <MobileSplashHero v-if="state=== 'splash'"></MobileSplashHero>
      <AppControls
        v-if="state === 'loaded'"
        :ap="audioPipeline"
        :apBus="apBus"
      ></AppControls>
    </div>
    <div
      id="player-mid"
      v-if="state === 'loaded'"
    >
      <VinylViz></VinylViz>
    </div>
    <div id="player-bot">
      <div
        ref="logoTxt"
        v-if="state === 'splash'"
        class="logo-txt"
        @click="onLogoClick"
      ><span>{{">ENTER<"}}<br></span>{{appName}}</div>
      <MeshViz
        :ap="audioPipeline"
        :apBus="apBus"
      ></MeshViz>
      <SocialChat
        ref="chat"
        v-if="state === 'loaded'"
        @send="sendChatMsg"
      ></SocialChat>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MobileSplashHero from "./Splash/MobileSplashHero.vue";
import MeshViz from "./SocialEngagement/MeshViz.vue";
import VinylViz from "./ArtistHighlight/VinylViz.vue";
import SocialChat from "./SocialEngagement/Chat/index.vue";
import AppControls from "./AppControls/index.vue";
import Analyzer from "./debug/Analyzer.vue";
import {
  IAudioEvents,
  AudioPipeline,
  CreateAudioPipeline,
} from "./lib/AudioPipeline";
import EventEmitter from "eventemitter3";

interface IChatMessage {
  username: string;
  message: string;
}
interface PlayerComponentData {
  curtains: null;
  apBus: EventEmitter<IAudioEvents>;
  audioPipeline: AudioPipeline;
  audioAnalyzer: null;
  state: string;
  appName: string;
  devControls: any;
  showDev: boolean;
  ws: WebSocket
}
export default defineComponent({
  name: "MobilePlayer",
  components: {
    MobileSplashHero,
    VinylViz,
    MeshViz,
    SocialChat,
    AppControls,
    Analyzer,
  },
  props: {},
  data(): PlayerComponentData {
    let ebus = new EventEmitter<IAudioEvents>();
    let loc = window.location;
    let uri = loc.protocol === 'https:' ? 'wss:' : 'ws:';
    uri = `${uri}//${loc.host}/ws`;
    return {
      curtains: null,
      apBus: ebus, // audio pipeline bus
      audioPipeline: CreateAudioPipeline(ebus),
      audioAnalyzer: null,
      state: "splash", // splash, loading, loaded
      appName: "FLOWSCAPE",
      showDev: false,
      ws: new WebSocket(uri),
      devControls: {
        mainGainDry: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        mainGainWet: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        streamGainDry: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        streamGainWet: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
        effectsGain: {
          val: 0,
          min: 0,
          max: 1,
          step: 0.1,
        },
      },
    };
  },
  methods: {
    sendChatMsg(msg: IChatMessage) {
      if (this.ws.readyState != WebSocket.OPEN) return;
      this.ws.send(JSON.stringify(msg));
    },
    onLogoClick() {
      if (this.state === "splash") {
        this.loadPlayer();
      } else if (this.state === "loaded") {
        this.state = "splash";
        this.audioPipeline.ctx.suspend();
      }
    },
    loadPlayer() {
      this.state = "loading";
      // if audio context hasn't been setup yet, do setup
      if (this.audioPipeline.getState() == "uninitialized") {
        this.audioPipeline.setup();
      } else {
        // otherwise, resume play
        this.audioPipeline.resume();
      }
      setTimeout(() => {
        this.state = "loaded";
      }, 1000);
    },
    onResize() {
      //this.setTxtRotate();
    },
    keyHandler(e: KeyboardEvent) {
      if (e.key === "Enter") {
        if (this.state === "splash") this.onLogoClick();
      } else if (e.code === "Backquote") {
        this.showDev = !this.showDev;
      }
    },
    updateAudioParam(newVal: number, k: string) {
      let control = this.devControls[k];
      control.val = +newVal;
      if (!control) return;
      this.apBus.emit("setParam", { paramName: k, paramVal: +control.val });
    },
  },
  created() {
    this.ws.onopen = () => {
      console.log('ws connected');
    };
    this.ws.onmessage = (evt: MessageEvent<any>) => {
      console.log("🚀 ~ file: index.vue ~ line 230 ~ created ~ evt", evt);
      // msg = { evt.username, evt.msg ...etc}
      (this.$refs.chat as any).recvMsg(JSON.parse(evt.data));
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("keypress", this.keyHandler);
    //this.setTxtRotate();
  },
  unmounted() {
    this.audioPipeline.destroy();
  },
});
</script>
<style scoped>
#player {
  display: flex;
  flex-flow: column;
  height: 100vh;
  background-color: var(--primary-color);
}

.control-row {
  display: flex;
  flex-flow: row;
  padding: 0.5em;
  width: 100%;
  justify-content: flex-end;
}
.control-row:nth-child(1) {
  flex: 1 1 0;
  padding: 1em;
}
#dev-control-panel {
  position: absolute;
  top: 20vh;
  right: 0;
  padding-right: 2em;
  width: 20vw;
  color: white;
  display: flex;
  flex-flow: column;
  justify-content: end;
  z-index: 100;
  background-color: var(--primary-color);
}
#dev-control-panel input {
  width: 80px;
  margin: 1em;
}
#dev-control-panel label {
  margin: 1em 0;
}

.logo-menu {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 50px;
  height: 50px;
  background-image: url("../../assets/waveburger.png");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.logo-txt > span {
  font-size: 0.5em;
  letter-spacing: 1em;
  font-weight: 600;
  margin-right: 2em;
  opacity: 0.6;
}
.logo-txt:hover > span {
  opacity: 0.9;
}
#player.splash .logo-txt {
  font-weight: 800;
  font-size: min(5vw, 4em);
  letter-spacing: 5vw;
  position: absolute;
  bottom: 140%;
  left: 0.5em;
  text-align: center;
  width: 100%;
  background-color: transparent;
  color: var(--secondary-color);
  animation: floata 6s infinite;
}
#player.loading .logo-txt {
}
.logo-txt {
  color: var(--primary-color);
  cursor: pointer;
  letter-spacing: 5vw;
  font-weight: 600;
  font-size: 2em;
  animation: none;
  margin-top: -2.5em;
  /* background-color: rgba(var(--secondary-color), 0.5); */

  transition: margin 0s, font-size 1s, padding 1s, background-color 2s,
    letter-spacing 1s;
}

#player.splash > #player-top {
  clip-path: polygon(100% 0, 0 0, 0 60%, 50% 33%, 100% 60%);
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: linear-gradient(
        90deg,
        var(--primary-color) calc(13px - 2px),
        transparent 1%
      )
      center,
    linear-gradient(var(--primary-color) calc(13px - 2px), transparent 1%)
      center,
    var(--secondary-dark-color);
  background-size: 13px 13px;
}
#player.loading > #player-top {
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  background: linear-gradient(
        90deg,
        var(--secondary-color) calc(13px - 2px),
        transparent 1%
      )
      center,
    linear-gradient(var(--secondary-color) calc(13px - 2px), transparent 1%)
      center,
    var(--primary-color);

  transition: all 1s, opacity 0s;
  background-size: 13px 13px;
}
#player.loaded > #player-top {
  opacity: 1;
  background: linear-gradient(
        90deg,
        var(--secondary-color) calc(13px - 2px),
        transparent 1%
      )
      center,
    linear-gradient(var(--secondary-color) calc(13px - 2px), transparent 1%)
      center,
    var(--primary-color);
  background-size: 13px 13px;
}
#player-top {
  position: relative;
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-backface-visibility: hidden;
  clip-path: polygon(100% 0, 0 0, 0 50%, 50% 100%, 100% 50%);

  transition: 1s all ease-in-out;

  animation: gradient 360s ease infinite;
}

#player.splash > #player-mid {
  display: none;
}
#player.loading > #player-mid {
  height: 0;
  opacity: 0;
}
#player-mid {
  height: calc(75vh - 200px);
  background-color: var(--primary-color);
  position: relative;

  transition: opacity 1s;
}

#player.splash > #player-bot {
  /* height: 15vh; */
  bottom: -20vh;
}
#player-bot {
  /* flex: 1 1 auto;
  position: relative; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 50vh;

  transition: 1s all;
}

@keyframes floata {
  0% {
    bottom: 125%;
  }
  50% {
    bottom: 115%;
  }
  100% {
    bottom: 125%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  33% {
    background-position: 0% -200%;
  }
  66% {
    background-position: -200% -400%;
  }
  100% {
    background-position: 0% 0%;
  }
}
</style>