
import { defineComponent } from "vue";
import VinylViz from "./VinylViz.vue";
export default defineComponent({
  name: "ArtistHighlight",
  components: {
    VinylViz
  },
  props: ["apBus", "song"]
})
