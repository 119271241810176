
import { defineComponent } from "vue";
import SplashHero from "@/components/Player/Splash/SplashHero.vue";
import ArtistHighlight from "@/components/Player/ArtistHighlight/index.vue";
import SocialEngagement from "@/components/Player/SocialEngagement/index.vue"
import AppControls from "@/components/Player/AppControls/index.vue";
import SidebarControls from "@/components/Player/AppControls/SidebarControls.vue"
import DevControlPanel from "@/components/Player/debug/DevControlPanel.vue"
import LogoSVG from "./logo.vue";
import {
  IAudioEvents,
  AudioPipeline,
  CreateAudioPipeline,
} from "@/components/Player/lib/AudioPipeline";
import EventEmitter from "eventemitter3";

// ISignalMessage is signaling message interface between client and server
export interface ISignalMessage {
  type: string;
  user: string;
  data: any;
}
// IStreamInfo is stream info interface sent from server
export interface IStreamInfo {
  name: string;
  artist: string;
}

export interface IChatMessage {
  username: string;
  message: string;
}
interface PlayerComponentData {
  curtains: null;
  apBus: EventEmitter<IAudioEvents>;
  audioPipeline: AudioPipeline;
  audioAnalyzer: null;
  state: string;
  appName: string;
  streamInfo: IStreamInfo;
  nextStreamInfo: IStreamInfo;
  showDev: boolean;
  ws: WebSocket;
  buildVersion: string;
  userCount: number;
}
export default defineComponent({
  name: "Player",
  components: {
    SplashHero,
    ArtistHighlight,
    AppControls,
    DevControlPanel,
    SocialEngagement,
    LogoSVG,
    SidebarControls
  },
  props: {},
  data(): PlayerComponentData {
    let ebus = new EventEmitter<IAudioEvents>();
    let loc = window.location;
    let uri = loc.protocol === 'https:' ? 'wss:' : 'ws:';
    uri = `${uri}//${loc.host}/ws`;
    return {
      curtains: null,
      apBus: ebus, // audio pipeline bus
      audioPipeline: CreateAudioPipeline(ebus),
      audioAnalyzer: null,
      state: "splash", // splash, loading, loaded
      appName: "FLOWSCAPE",
      showDev: false,
      ws: new WebSocket(uri),
      streamInfo: { name: '', artist: '' },
      nextStreamInfo: { name: '', artist: '' },
      buildVersion: "alpha build - Baragon 4",
      userCount: 1
    };
  },
  methods: {
    /*
    type Message struct {
      Type string // enum later?
      User string // temp username, eventually User struct 
      Data MessageData
    }
    */
    sendChatMsg(msg: IChatMessage) {
      if (this.ws.readyState != WebSocket.OPEN) return;
      let final = JSON.stringify({ Type: "chat", User: msg.username, Data: msg.message });
      this.ws.send(final);
    },
    onLogoClick() {
      if (this.state === "splash") {
        this.loadPlayer();
      } else if (this.state === "loaded") {
        this.state = "splash";
        this.audioPipeline.ctx.suspend();
      }
    },
    loadPlayer() {
      this.state = "loading";
      // if audio context hasn't been setup yet, do setup
      if (this.audioPipeline.getState() == "uninitialized") {
        this.audioPipeline.setup();
      } else {
        // otherwise, resume play
        this.audioPipeline.resume();
      }
      setTimeout(() => {
        this.state = "loaded";
      }, 1000);
    },
    onResize() {
      //this.setTxtRotate();
    },
    keyHandler(e: KeyboardEvent) {
      if (e.key === "Enter") {
        if (this.state === "splash") this.onLogoClick();
      } else if (e.code === "Backquote") {
        this.showDev = !this.showDev;
      }
    },
  },
  created() {
    this.ws.onopen = () => {
      //console.log('ws connected');
    };
    this.ws.onmessage = (evt: MessageEvent<any>) => {
      let msg: ISignalMessage = JSON.parse(evt.data);
      if (msg.type == 'chat') {
        (this.$refs.sidebarControls as any).recvMsg(msg);
      } else if (msg.type == 'next_song') {
        if (this.streamInfo.name == '') {
          this.streamInfo = msg.data; // handle first conn
          (this.$refs.sidebarControls as any).recvMsg({
            user: '▂▃▅▇█ flowscape █▇▅▃▂',
            data: `Now Playing: ${this.streamInfo.name} by ${this.streamInfo.artist}`
          });
        } else {
          this.nextStreamInfo = msg.data; // buffer since comes early
        }
      } else if (msg.type == 'song_change') {
        this.streamInfo = this.nextStreamInfo;
        this.nextStreamInfo = { name: '', artist: '' };
        (this.$refs.sidebarControls as any).recvMsg({
          user: '▂▃▅▇█ flowscape █▇▅▃▂',
          data: `Now Playing: ${this.streamInfo.name} by ${this.streamInfo.artist}`
        });
      } else if (msg.type == 'user_count') {
        this.userCount = msg.data;
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("keypress", this.keyHandler);
    //this.setTxtRotate();
  },
  unmounted() {
    this.audioPipeline.destroy();
  },
});
