<template>
  <div id="mesh-viz">
    <canvas
      ref="canvas"
      id="mesh-viz-canvas"
      @mousemove="onMouseMove"
      @mousedown="onMouseDown"
      @mouseup="onMouseUp"
    ></canvas>
  </div>
</template>
<style scoped>
/* #mesh-viz {
  width: 100%;
  height: 100%;
} */
#mesh-viz {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}
</style>
<script>
import { defineComponent } from "vue";
import * as twgl from "twgl.js";
// const m4 = twgl.m4;
// const primitives = twgl.primitives;
import frag from '@/shaders/pointSpheres.frag';
import vert from '@/shaders/pointSpheres.vert';

export default defineComponent({
  name: "MeshViz",
  props: ["ap", "apBus"],
  data() {
    return {
      gl: null,
      sps: 60, // simulations per second
      prevRAFTime: 0,
      startRAFTime: 0,

      iProg: null, // init program
      dProg: null, // draw program

      positionBuff: null,
      pointsBuff: null,

      // framebuffers
      fb1: null,

      // shader params
      mouse: { x: 0, y: 0 },
      lightWPos: [1, 8, -10],
      lightColor: [1, 1, 1, 1],
      camera: twgl.m4.identity(),
      view: twgl.m4.identity(),
      viewProjection: twgl.m4.identity(),

      // yii
      timeWaveform: [],
      beatImpulse: 0, // [0, 1] set to 1 on beat and deteriorates at a rate to 0
    };
  },
  methods: {
    simulate() {
      if (this.beatImpulse != 0) this.beatImpulse = Math.max(0, this.beatImpulse - (1 / this.sps) * 2);
    },
    animate(now) {
      requestAnimationFrame(this.animate);

      let time = now / 1000;

      if (!this.startRAFTime) this.startRAFTime = this.prevRAFTime = time;
      let deltaTime = time - this.prevRAFTime;
      // let totalTime = time - this.startRAFTime;
      this.draw(deltaTime);
      this.prevRAFTime = time;
    },
    draw(deltaTime) {
      twgl.resizeCanvasToDisplaySize(this.gl.canvas);
      this.gl.viewport(0, 0, this.gl.canvas.width, this.gl.canvas.height);

      this.gl.enable(this.gl.DEPTH_TEST);
      //this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);

      const projection = twgl.m4.perspective(
        (90 * Math.PI) / 180,
        this.gl.canvas.clientWidth / this.gl.canvas.clientHeight,
        0.25,
        1000
      );
      const eye = [0, 3, -5];//[-3, 1.75, 6];//[-1.5, .75, 1];
      const target = [0, 1, 1];//[1.5, -.5, 6];//[0, .25, 1];
      const up = [0, 1, 0];
      const world = twgl.m4.identity();

      twgl.m4.lookAt(eye, target, up, this.camera);
      twgl.m4.inverse(this.camera, this.view);
      twgl.m4.multiply(projection, this.view, this.viewProjection);

      let u_worldViewProjection = twgl.m4.identity();
      twgl.m4.multiply(this.viewProjection, world, u_worldViewProjection);

      // particle draw
      let time = this.prevRAFTime + deltaTime;

      // temp try to update pointsbuff with audio data
      // const m = 256, n = 256;
      // const pointData = [];
      // let timeWaveform = [];
      // update timewaveform
      // if (this.ap.analyzer.normalizedTimeWaveform) {
      //   for (let i = 255; i > 0; i--) {
      //     this.timeWaveform[i] = this.timeWaveform[i - 1];
      //   }
      //   this.timeWaveform[0] = this.ap.analyzer.normalizedTimeWaveform;
      // }
      // for (let i = 0; i < n; ++i) {
      //   for (let j = 0; j < m; ++j) {
      //     pointData.push(i / (n - 1));
      //     pointData.push(j / (m - 1));
      //     timeWaveform.push(this.timeWaveform[i][j]);
      //   }
      // }
      // const pointsObj = {
      //   v_texcoord: { data: pointData, numComponents: 2 },
      //   v_texamplitude: { data: timeWaveform, numComponents: 1 }
      // };
      // this.pointsBuff = twgl.createBufferInfoFromArrays(this.gl, pointsObj);

      if (this.ap.analyzer.normalizedTimeWaveform) {
        this.timeWaveform = this.ap.analyzer.normalizedWaveform;//.slice(0, 128);
      }

      this.gl.useProgram(this.dProg.program);
      twgl.setBuffersAndAttributes(this.gl, this.dProg, this.pointsBuff);
      twgl.setUniforms(this.dProg, {
        u_texture: this.fb1.attachments[0],
        u_worldViewProjection: u_worldViewProjection,
        u_resolution: [this.gl.canvas.clientWidth, this.gl.canvas.clientHeight],
        u_mouse: [this.mouse.x, this.mouse.y],
        u_time: time,
        dt: 2.5 * deltaTime,
        u_beatImpulse: this.beatImpulse,
        u_fft: this.timeWaveform
      });
      twgl.bindFramebufferInfo(this.gl, null);
      twgl.drawBufferInfo(this.gl, this.pointsBuff, this.gl.POINTS);
    },
    // normalize mouse pos to [0, 1] range
    onMouseMove(e) {
      if (!this.gl) return;
      let rect = e.target.getBoundingClientRect();
      this.mouse.x = (e.clientX - rect.left) / this.gl.canvas.clientWidth;
      this.mouse.y = 1 - (e.clientY - rect.top) / this.gl.canvas.clientHeight;
      // this.mouse.x = e.clientX / this.gl.canvas.clientWidth;
      // this.mouse.y = 1 - e.clientY / this.gl.canvas.clientHeight;
    },
    onMouseDown(e) {
      console.log("🚀 ~ file: MeshViz.vue ~ line 46 ~ onMouseDown ~ e", e);
    },
    onMouseUp(e) {
      console.log("🚀 ~ file: MeshViz.vue ~ line 49 ~ onMouseUp ~ e", e);
    },
  },
  mounted() {
    this.$refs.canvas.height = document.getElementById("mesh-viz").clientHeight;
    this.$refs.canvas.width = document.getElementById("mesh-viz").clientWidth;
    this.apBus.on("beat-detect", () => (this.beatImpulse = 0.5));

    // TODO: wrap thish particle ish in a reusable class l8r
    this.gl = twgl.getContext(this.$refs.canvas, {
      depth: false,
      antialias: false,
      //preserveDrawingBuffer: true,
    });

    // particle initialization
    // temp shaders from twgl examp: https://twgljs.org/examples/gpgpu-particles.html
    // todo: glsl file importer?
    const vs = `
      precision mediump float;
      attribute vec2 position;
      varying vec2 v_position;
      
      void main() {
        v_position = position;//0.5 * position + 0.5;
        gl_Position = vec4(position, 0.0, 1.0);
      }`;
    const fs = `
      precision mediump float;
      varying vec2 v_position;

      void main() {
        gl_FragColor = vec4(v_position, 0.0, 0.0);
      }`;
    const vDraw = vert;
    const fDraw = frag;

    this.iProg = twgl.createProgramInfo(this.gl, [vs, fs]);
    this.dProg = twgl.createProgramInfo(this.gl, [vDraw, fDraw]);

    const n = 256;
    const m = 256;
    this.fb1 = twgl.createFramebufferInfo(this.gl, undefined, n, m);
    const posObj = {
      position: { data: [1, 1, 1, -1, -1, -1, -1, 1, 1, 1, -1, -1], numComponents: 2 },
    };
    this.positionBuff = twgl.createBufferInfoFromArrays(this.gl, posObj);

    const pointData = [];
    // let timeWaveform = [];
    let id = 0;
    for (let i = 0; i < n; ++i) {
      this.timeWaveform.push(.5);
      // this.timeWaveform.push([])
      for (let j = 0; j < m; ++j) {
        pointData.push(i / (n - 1));
        pointData.push(j / (m - 1));
        pointData.push(id++);
        // this.timeWaveform[i].push(0)
        // timeWaveform.push(0);
      }
    }
    // initialize timewaveform
    // for (let i = 0; i < 256; i++) {
    //   this.timeWaveform.push([])
    //   for (let j = 0; j < 256; j++) {
    //     this.timeWaveform[i].push(0);
    //   }
    // }
    const pointsObj = {
      v_texcoord: { data: pointData, numComponents: 3 },
      // v_texamplitude: { data: timeWaveform, numComponents: 1 }
    };
    this.pointsBuff = twgl.createBufferInfoFromArrays(this.gl, pointsObj);

    // particles init
    this.gl.useProgram(this.iProg.program);
    twgl.setBuffersAndAttributes(this.gl, this.iProg, this.positionBuff);
    twgl.bindFramebufferInfo(this.gl, this.fb1);
    twgl.drawBufferInfo(this.gl, this.positionBuff, this.gl.POINTS); // make the type a prop l8r like vertexshaderart?

    // trigger rAF animate loop
    // we want this to run full-blast and not throttle it or do heavy computations
    requestAnimationFrame(this.animate);

    // run simulation separate from animation loop
    // so we can target a specific simulation rate (sps)
    // todo: put in web worker? not sure yet what the perf will be for this viz
    setInterval(() => {
      this.simulate();
    }, 1000 / this.sps);

    //Object.seal(this.timeWaveform);
    // setInterval(() => {
    //   for (let i = 255; i > 0; i--) {
    //     this.timeWaveform[i] = this.timeWaveform[i - 1];
    //   }
    //   this.timeWaveform[0] = this.ap.analyzer.normalizedTimeWaveform;
    //   //console.log("🚀 ~ file: MeshViz.vue ~ line 243 ~ setInterval ~ this.timeWaveform", this.timeWaveform.length)
    // }, 50)
  },
});
</script>
