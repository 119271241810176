
import { defineComponent } from "vue";
export default defineComponent({
  name: "SplashHero",
  data() {
    return {
      isMobile: false
    }
  },
  mounted: function () {
    this.isMobile = document.body.clientWidth < 1200;
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.isMobile = document.body.clientWidth < 1200;
    }
  }
})
