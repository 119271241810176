import EventEmitter from "eventemitter3";
import { IAudioEvents } from "./AudioPipeline";

interface IAudioAnalyzerOptions {
  fftSize: number;
  sampleRate: number;
  beatDecay: number;
  beatDebounce: number;
}
export default function CreateAudioAnalyzer(ctx: AudioContext, ebus: EventEmitter<IAudioEvents>, config?: IAudioAnalyzerOptions ): AudioAnalyzer {
  return new AudioAnalyzer(ctx, ebus, config);
}

var defaultConfig = {
  fftSize: 256,
  sampleRate: 50, // in ms
  beatDecay: 0.15, // % decay per second
  beatDebounce: 300 // debounce in ms
};

/* AudioAnalyzer is a Web Audio API wrapper class useful for sound visualizations
 * Created by: Sammy G
 * License: use for whatever yo
 */
export class AudioAnalyzer {
  public node: AnalyserNode;

  public waveform: Uint8Array;
  public normalizedWaveform: number[] | null;
  public timeWaveform: Uint8Array;
  public normalizedTimeWaveform: number[] | null;
  public volume: number;
  public normalizedVolume: number;
  public maxVolume: number;
  public beatThreshold: number;
  public normalizedBeatThreshold: number;
  
  private _ebus: EventEmitter<IAudioEvents>;
  private _ctx: AudioContext;
  private _config: IAudioAnalyzerOptions;

  private _lastBeatTime: number;
  private _interval: number;


  constructor(ctx: AudioContext, ebus: EventEmitter<IAudioEvents>, config?: IAudioAnalyzerOptions) {
    this._config = {
      ...config,
      ...defaultConfig
    };
    this._ctx = ctx;
    this._ebus = ebus || null;
    this.node = this._ctx.createAnalyser();
    this.node.fftSize = this._config.fftSize;

    // useful props for visualizers
    this.waveform = new Uint8Array(128);
    this.normalizedWaveform = null;
    this.timeWaveform = new Uint8Array(this.node.frequencyBinCount);
    this.normalizedTimeWaveform = null;
    this.volume = 0;
    this.normalizedVolume = 0;

    this.maxVolume = 256;

    // beat detection
    this.beatThreshold = 0;
    this.normalizedBeatThreshold = 0;
    this._lastBeatTime = 0;

    this._interval = -1;
  }

  public setup() {
    this._interval = window.setInterval(() => {
      this._sampleAudio();
    }, this._config.sampleRate);
  }

  public destroy() {
    window.clearInterval(this._interval);
    this.node.disconnect();

    this._unbindEvents();
  }

  private _unbindEvents(): void {

  }

  private _bindEvents(): void {

  }

  private _sampleAudio() {
    this.node.getByteFrequencyData(this.waveform);
    this.node.getByteTimeDomainData(this.timeWaveform);
    this.volume = this.waveform.reduce((total, val) => total + val, 0);

    // temp normalize formulas
    this.normalizedVolume = this.volume / (this.maxVolume * this.waveform.length);
    this.normalizedWaveform = Array.from(this.waveform).map(a => a / 128);
    this.normalizedTimeWaveform = Array.from(this.timeWaveform).map(
      a => a / 128
    );

    // beat detect
    this.beatThreshold -=(this._config.beatDecay * this.beatThreshold) / this._config.sampleRate;
    this.normalizedBeatThreshold -=
      (this._config.beatDecay * this.normalizedBeatThreshold) /
      this._config.sampleRate;

    if (this.volume > this.beatThreshold) {
      this.beatThreshold = this.volume;
      this.normalizedBeatThreshold = this.normalizedVolume;
      let beatTime = Date.now() - this._lastBeatTime;
      if (this._ebus && beatTime > this._config.beatDebounce) {
        this._lastBeatTime = Date.now();
        this._ebus.emit("beat-detect"); //debounce to prevent some false-positives?
      }
    }
  }
}