<template>
  <div
    id="player"
    :class="state"
  >
    <DevControlPanel
      v-if="showDev"
      :audioPipeline="audioPipeline"
      :apBus="apBus"
    ></DevControlPanel>
    <SidebarControls
      v-if="state === 'loaded'"
      ref="sidebarControls"
      :audioPipeline="audioPipeline"
      :apBus="apBus"
      :userCount="userCount"
      @send="sendChatMsg"
    ></SidebarControls>
    <div
      id="player-top"
      ref="playerTop"
    >
      <div id="bg"></div>
      <div
        v-if="state === 'loaded'"
        class="logo-menu-base tri-out"
        title="home"
        @click="onLogoClick"
      ></div>
      <SplashHero
        v-if="state=== 'splash'"
        style="z-index: 2"
      ></SplashHero>
      <AppControls
        v-if="state === 'loaded'"
        :ap="audioPipeline"
        :apBus="apBus"
        :streamInfo="streamInfo"
      ></AppControls>
    </div>
    <div id="player-bot">
      <div
        id="player-bot-inner"
        @click="if(state === 'splash') { onLogoClick(); }"
      >
        <div
          ref="logoTxt"
          v-if="state === 'splash'"
          class="logo-txt"
        >
          <span>{{">ENTER<"}}<br></span>
        </div>
        <SocialEngagement
          ref="social"
          class="social-engagement"
          :audioPipeline="audioPipeline"
          :apBus="apBus"
        ></SocialEngagement>
      </div>
    </div>
    <div id="player-footer">
      <span
        id="build-version"
        title="build version"
      >{{buildVersion}}</span>
      <div
        id="stream-info"
        v-if="state === 'loaded'"
        class="footer-item"
      >
        <span>{{streamInfo.artist}} - {{streamInfo.name}}</span>
      </div>
      <div></div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SplashHero from "@/components/Player/Splash/SplashHero.vue";
import ArtistHighlight from "@/components/Player/ArtistHighlight/index.vue";
import SocialEngagement from "@/components/Player/SocialEngagement/index.vue"
import AppControls from "@/components/Player/AppControls/index.vue";
import SidebarControls from "@/components/Player/AppControls/SidebarControls.vue"
import DevControlPanel from "@/components/Player/debug/DevControlPanel.vue"
import LogoSVG from "./logo.vue";
import {
  IAudioEvents,
  AudioPipeline,
  CreateAudioPipeline,
} from "@/components/Player/lib/AudioPipeline";
import EventEmitter from "eventemitter3";

// ISignalMessage is signaling message interface between client and server
export interface ISignalMessage {
  type: string;
  user: string;
  data: any;
}
// IStreamInfo is stream info interface sent from server
export interface IStreamInfo {
  name: string;
  artist: string;
}

export interface IChatMessage {
  username: string;
  message: string;
}
interface PlayerComponentData {
  curtains: null;
  apBus: EventEmitter<IAudioEvents>;
  audioPipeline: AudioPipeline;
  audioAnalyzer: null;
  state: string;
  appName: string;
  streamInfo: IStreamInfo;
  nextStreamInfo: IStreamInfo;
  showDev: boolean;
  ws: WebSocket;
  buildVersion: string;
  userCount: number;
}
export default defineComponent({
  name: "Player",
  components: {
    SplashHero,
    ArtistHighlight,
    AppControls,
    DevControlPanel,
    SocialEngagement,
    LogoSVG,
    SidebarControls
  },
  props: {},
  data(): PlayerComponentData {
    let ebus = new EventEmitter<IAudioEvents>();
    let loc = window.location;
    let uri = loc.protocol === 'https:' ? 'wss:' : 'ws:';
    uri = `${uri}//${loc.host}/ws`;
    return {
      curtains: null,
      apBus: ebus, // audio pipeline bus
      audioPipeline: CreateAudioPipeline(ebus),
      audioAnalyzer: null,
      state: "splash", // splash, loading, loaded
      appName: "FLOWSCAPE",
      showDev: false,
      ws: new WebSocket(uri),
      streamInfo: { name: '', artist: '' },
      nextStreamInfo: { name: '', artist: '' },
      buildVersion: "alpha build - Baragon 4",
      userCount: 1
    };
  },
  methods: {
    /*
    type Message struct {
      Type string // enum later?
      User string // temp username, eventually User struct 
      Data MessageData
    }
    */
    sendChatMsg(msg: IChatMessage) {
      if (this.ws.readyState != WebSocket.OPEN) return;
      let final = JSON.stringify({ Type: "chat", User: msg.username, Data: msg.message });
      this.ws.send(final);
    },
    onLogoClick() {
      if (this.state === "splash") {
        this.loadPlayer();
      } else if (this.state === "loaded") {
        this.state = "splash";
        this.audioPipeline.ctx.suspend();
      }
    },
    loadPlayer() {
      this.state = "loading";
      // if audio context hasn't been setup yet, do setup
      if (this.audioPipeline.getState() == "uninitialized") {
        this.audioPipeline.setup();
      } else {
        // otherwise, resume play
        this.audioPipeline.resume();
      }
      setTimeout(() => {
        this.state = "loaded";
      }, 1000);
    },
    onResize() {
      //this.setTxtRotate();
    },
    keyHandler(e: KeyboardEvent) {
      if (e.key === "Enter") {
        if (this.state === "splash") this.onLogoClick();
      } else if (e.code === "Backquote") {
        this.showDev = !this.showDev;
      }
    },
  },
  created() {
    this.ws.onopen = () => {
      //console.log('ws connected');
    };
    this.ws.onmessage = (evt: MessageEvent<any>) => {
      let msg: ISignalMessage = JSON.parse(evt.data);
      if (msg.type == 'chat') {
        (this.$refs.sidebarControls as any).recvMsg(msg);
      } else if (msg.type == 'next_song') {
        if (this.streamInfo.name == '') {
          this.streamInfo = msg.data; // handle first conn
          (this.$refs.sidebarControls as any).recvMsg({
            user: '▂▃▅▇█ flowscape █▇▅▃▂',
            data: `Now Playing: ${this.streamInfo.name} by ${this.streamInfo.artist}`
          });
        } else {
          this.nextStreamInfo = msg.data; // buffer since comes early
        }
      } else if (msg.type == 'song_change') {
        this.streamInfo = this.nextStreamInfo;
        this.nextStreamInfo = { name: '', artist: '' };
        (this.$refs.sidebarControls as any).recvMsg({
          user: '▂▃▅▇█ flowscape █▇▅▃▂',
          data: `Now Playing: ${this.streamInfo.name} by ${this.streamInfo.artist}`
        });
      } else if (msg.type == 'user_count') {
        this.userCount = msg.data;
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("keypress", this.keyHandler);
    //this.setTxtRotate();
  },
  unmounted() {
    this.audioPipeline.destroy();
  },
});
</script>
<style>
#player {
  display: flex;
  flex-flow: column;
  height: 100vh;
  /* background-color: var(--primary-color); */
  background: linear-gradient(270deg, #000036 -20%, #000006);
}
#player-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#build-version {
  color: var(--secondary-color);
  font-family: var(--primary-font-family);
  font-size: 0.8em;
  font-style: italic;
  padding: 2em;
  cursor: default;
}
.footer-item {
  display: flex;
  flex-direction: column;
  align-content: center;
  font-size: 1.5em;
  text-decoration: none;
  color: var(--secondary-color);
  filter: drop-shadow(0px 0px 2px var(--secondary-color));
  margin: 0 1em;
  cursor: pointer;
  color: var(--secondary-color);
  font-family: var(--primary-font-family);
}
.logo-menu {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  background-image: url("../../assets/waveburger_secondary.png");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.logo-menu-base {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}
.tri-out {
  color: var(--secondary-color);
  transform: scale(1.5);
  width: 22px;
  height: 17px;
  border-left: 3px solid transparent;
  border-bottom: 3px solid;
}
.tri-out,
.tri-out::before {
  display: block;
  box-sizing: border-box;
  border-right: 3px solid transparent;
}
.tri-out::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-left: 3px solid;
  border-top: 3px solid;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg) skew(10deg, 10deg);
  left: -2px;
  bottom: -13px;
}
.logo-txt > span {
  font-size: 0.75em;
  letter-spacing: 0.75em;
  font-weight: 600;
  margin-left: 1em;
}
.logo-txt {
  color: var(--primary-color);
  cursor: pointer;
  letter-spacing: 4vw;
  font-weight: 600;
  font-size: 2em;
  animation: logoglow 2s infinite;
  top: 46%;
  transition: margin 0s, font-size 1s, padding 1s, background-color 2s,
    letter-spacing 1s;
}
#player.splash .logo-txt {
  font-weight: 800;
  font-size: min(5vw, 4em);
  letter-spacing: 20px;
  position: absolute;
  text-align: center;
  width: 100%;
  background-color: transparent;
  color: var(--secondary-color);
  animation: floata 6s infinite, logoglow 2s infinite;
}
#bg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}
#player-top {
  position: relative;
  z-index: 1;
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-backface-visibility: hidden;
  transition: 1s all ease-in-out;
}
#player.splash > #player-top {
  z-index: 0;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
#player.loading > #player-top {
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#player.loaded > #player-top {
  opacity: 1;
}

#player.splash > #player-mid {
  display: none;
}
#player.loading > #player-mid {
  height: 0;
  opacity: 0;
}
#player-mid {
  height: calc(75vh - 200px);
  /* background-color: var(--primary-color); */
  position: relative;
  z-index: 1;
  transition: opacity 1s;
}

#player-bot-inner {
  width: 100%;
  height: 100%;
  clip-path: polygon(28% 86%, 50% 4%, 72% 86%);
  background: linear-gradient(270deg, #000036 -20%, #000016);
  transition: 1s all, height 0.5s;
}
#player-bot {
  /* flex: 1 1 auto;
  position: relative; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 70vh;
  transition: 1s all, height 0.5s;
}
#player.splash > #player-bot {
  /* background-color: var(--secondary-color); */
  clip-path: polygon(25% 90%, 50% 0%, 75% 90%);
  cursor: pointer;
}
#player.loading > #player-bot {
  width: 100vw;
  height: 100vh;
  clip-path: polygon(-175% 200%, 50% -75%, 275% 200%);
}
#player.loading #player-bot-inner {
  clip-path: polygon(-175% 200%, 50% -75%, 275% 200%);
  background: linear-gradient(270deg, #000036 -20%, #000006);
}
#player-bot .social-engagement {
  padding-top: 10%;
}
#player.loaded #player-bot,
#player.loaded #player-bot-inner {
  clip-path: none;
  background: transparent;
}
@keyframes logoglow {
  0% {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0));
  }

  70% {
    filter: drop-shadow(0px 0px 5px var(--secondary-color));
  }

  100% {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0));
  }
}
@keyframes floata {
  0% {
    bottom: 115%;
  }
  50% {
    bottom: 105%;
  }
  100% {
    bottom: 115%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  33% {
    background-position: 0% -200%;
  }
  66% {
    background-position: -200% -400%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@media only screen and (max-width: 1200px) {
  #player-bot-inner {
    clip-path: polygon(10% 86%, 50% 4%, 90% 86%);
    background: linear-gradient(0deg, #000036 50%, #000016);
  }
  #player.splash > #player-bot {
    clip-path: polygon(5% 90%, 50% 0%, 95% 90%);
  }
}
</style>