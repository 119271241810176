<template>
  <div class="splash-hero">
    <div class="copybit">
      <p>While listening, donate directly to artists with</p>
      <span class="flow-font">H Y P E C O I N</span>
    </div>
    <div class="copybit">
      <p>Welcome to</p><span
        class="flow-font"
        style="font-size: calc(1.25em + 0.5vw);margin:.5em"
      >F L O W S C A P E</span>
      <p>a 24/7 digital music festival of new electronic music.</p>
    </div>
    <div class="copybit">
      <p style="margin-top:.5em">Now playing on the main stage:</p>
      <span class="flow-font">A L E X</span>
      <span class="flow-font">H O L O N</span>
      <span class="flow-font">R O S E N T W I G</span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "SplashHero"
})
</script>
<style scoped>
.splash-hero {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 33%;
  width: 100%;
  margin: 1em 0;
  margin-top: 3em;
}
.splash-hero > div {
  color: var(--secondary-color);
  font-size: calc(1em + 0.5vw);
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.flow-font {
  text-shadow: 6px 3px 4px var(--accent-color);
  font-weight: 800;
}
.copybit {
  padding: 0.5em;
  flex: 1 1 0;
  align-items: center;
}
.copybit > p:nth-child(2) {
  margin-left: 0%;
}
.splash-hero > .copybit:nth-child(1) {
  justify-content: center;
  margin-top: 25vh;
}
.splash-hero > .copybit:nth-child(2) {
  justify-content: flex-start;
}
.splash-hero > .copybit:nth-child(3) {
  justify-content: center;
  margin-top: 50vh;
}
.splash-hero p {
  padding: 0;
  margin: 0;
  word-break: keep-all;
  white-space: nowrap;
}
</style>