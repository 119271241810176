<template>
  <div id="sidebar-controls">
    <div id="sidebar">
      <div class="sidebar-top">
        <div
          id="chat-tog"
          :class="['sidebar-item', active=='chat' ? 'active' : '']"
          @click="itemClick($event, 'chat')"
        >💬</div>
        <div
          id="chat-tog"
          :class="['sidebar-item', active=='info' ? 'active' : '']"
          @click="itemClick($event, 'info')"
        >🛈</div>
      </div>
      <div class="sidebar-bottom">
        <AppControls
          :ap="audioPipeline"
          :apBus="apBus"
        ></AppControls>
        <div id="volume-slider">
          <input
            type="range"
            min="1"
            max="100"
            orient="vertical"
            id="volume"
            v-model="volume"
            @input="updateVolume"
            @wheel="updateVolumeFromWheel"
          >
        </div>
      </div>
    </div>
    <div id="sidebar-widget">
      <Chat
        ref="chat"
        v-if="active === 'chat'"
        :userCount="userCount"
        @send="sendChatMsg"
      ></Chat>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Chat from "@/components/Player/SocialEngagement/Chat/index.vue";
import { IChatMessage, ISignalMessage } from "@/views/Player/index.vue";
import AppControls from "@/components/Player/AppControls/index.vue";

export default defineComponent({
  name: "SidebarControls",
  components: {
    Chat,
    AppControls
  },
  props: ["audioPipeline", "apBus", "userCount"],
  data() {
    return {
      active: 'chat',
      volume: 50,
    };
  },
  methods: {
    itemClick(event: Event, type: string) {
      if (type === 'chat') {
        if (this.active === 'chat') {
          // chat already active and clicked again
          this.active = "";
        } else {
          // show chat and hide previous widget
          this.active = type;
        }
      } else {
        this.active = type
      }

    },
    sendChatMsg(msg: IChatMessage) {
      this.$emit('send', msg);
    },
    recvMsg(msg: ISignalMessage) {
      (this.$refs.chat as any).recvMsg(msg);
    },
    updateVolume(): void {
      this.volume = Math.min(100, Math.max(0, this.volume));
      if (!this.apBus) return;
      this.apBus.emit("setParam", {
        paramName: "streamGain",
        paramVal: this.volume / 100, // map to [0, 1]
      });
    },
    updateVolumeFromWheel(e: WheelEvent): void {
      this.volume = Math.min(100, Math.max(0, this.volume + e.deltaY * -0.05));
      if (!this.apBus) return;
      this.apBus.emit("setParam", {
        paramName: "streamGain",
        paramVal: this.volume / 100, // map to [0, 1]
      });
    },
  },
});
</script>
<style scoped>
#sidebar {
  position: absolute;
  top: 10vh;
  right: 0;
  width: 2em;
  height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  z-index: 10;
}
.sidebar-top {
  display: flex;
  flex-direction: column;
}
.sidebar-bottom {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1em;
}
.sidebar-item {
  display: flex;
  flex-flow: row;
  justify-content: center;
  font-size: 1.5em;
  cursor: pointer;
  color: white;
}
.sidebar-item.active {
  background-color: var(--secondary-color);
  color: black;
}
.sidebar-item:hover {
  background-color: var(--secondary-color);
  color: black;
}
#volume-slider {
  height: 120px;
  width: 100%;
  align-self: flex-end;
}
#volume-slider > input {
  width: 100%;
  height: 100%;
  -webkit-appearance: slider-vertical;
}
#sidebar-widget {
  width: 25vw;
  height: 100vh;
  position: absolute;
  left: 75vw;
  top: 0;
  padding-top: 0;
  margin-left: -0.5em;
}

/*Chrome*/
#volume-slider > input[type="range"] {
  overflow: hidden;
  background-color: #9a905d;
}
/* 
#volume-slider > input[type="range"]::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  color: #13bba4;
  margin-top: -1px;
}

#volume-slider > input[type="range"]::-webkit-slider-thumb {
  width: 30px;
  -webkit-appearance: none;
  height: 10px;
  cursor: ew-resize;
  background: #434343;
  box-shadow: -20px 0 0 20px #43e5f7;
} */
/** FF*/
/* #volume-slider > input[type="range"]::-moz-range-progress {
  background-color: #43e5f7;
}
#volume-slider > input[type="range"]::-moz-range-track {
  background-color: #9a905d;
} */
/* IE*/
/* #volume-slider > input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7;
}
#volume-slider > input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
} */
</style>