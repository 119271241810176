<template>
  <div class="row">
    <span class="input-before">&#8594;</span>
    <input
      id="chat-input"
      v-model="value"
      :placeholder="placeholder"
      @keydown="onKeyDown"
      @blue="onBlur"
      autocomplete="off"
      maxlength="126"
    />
  </div>
</template>
<style scoped>
.row {
  display: flex;
  flex-flow: row;
  width: 100%;
}
#chat-input {
  flex: 1 1 auto;
  width: 100%;
  padding: 2px 2px 2px 1.5em;
  background: none;
  background-image: none;
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
  border-radius: 0;
  transition: border-color 0.25s ease, box-shadow 0.25s ease;
}
#chat-input:focus {
  outline: 0;
  border-color: var(--secondary-color);
}
.input-before {
  position: absolute;
  margin-left: 4px;
  padding: 0;
  color: var(--accent-color);
  font-size: 1em;
  line-height: 150%;
}
</style>
<script>
export default {
  props: ["placeholder"],
  data() {
    return {
      value: "",
      history: [],
      maxHistory: 10,
      historyIndex: -1
    };
  },
  methods: {
    validateMsg(msg) {
      return msg && msg.trim().length > 0;
    },
    onKeyDown(e) {
      if (e.key === "Enter") this.emitMsg();
      else if (e.key === "ArrowUp") this.setFromHistoryR();
      else if (e.key === "ArrowDown") this.setFromHistoryF();
    },
    onBlur() {
      this.historyIndex = -1;
    },
    emitMsg() {
      // do validation
      if (!this.validateMsg(this.value)) return;
      this.$emit("send", this.value);
      this.updateHistory(this.value);
      this.value = ""; // temp, l8r take some possible error/validation msg from parent
      this.historyIndex = -1;
    },
    updateHistory(value) {
      if (this.history.length >= this.maxHistory) this.history.shift();
      this.history.push(value);
    },
    setFromHistoryR() {
      if (this.historyIndex <= 0) this.historyIndex = this.history.length - 1;
      else this.historyIndex--;
      this.value = this.history[this.historyIndex];
    },
    setFromHistoryF() {
      if (this.historyIndex >= this.history.length - 1) this.historyIndex = 0;
      else this.historyIndex++;
      this.value = this.history[this.historyIndex];
    }
  }
};
</script>
