<template>
  <div id="err-404">
    <div>Nah</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "404",
})
</script>
<style>
#err-404 {
  width: 100vw;
  height: 100vh;
}
</style>