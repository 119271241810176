<template>
  <div id="social-chat">
    <div class="chat-header">
      <div>
        <span
          class="secondary-color"
          style="font-weight:bold;"
        >{{userCount}}</span>
        <span>&nbspTraveler{{userCount > 1 ? 's' : ''}} online&nbsp</span>
      </div>
    </div>
    <div class="chat-window">
      <ChatInput
        :placeholder="inputPlaceholder"
        @send="sendMsg"
      ></ChatInput>
      <div class="chat-msgs">
        <ChatMessage
          v-for="chat in chatsChronoSorted"
          :key="chat.id"
          :id="chat.id"
          :msg="chat.msg"
          :username="chat.username"
          :avatar="chat.avatar"
          :data-pending="chat.pending"
          :data-self="username == chat.username"
        >
        </ChatMessage>
      </div>
    </div>
  </div>
</template>
<style scoped>
#social-chat {
  min-width: 100px;
  height: 100%;
  display: flex;
  flex-flow: row;
  flex-direction: column;
  margin-left: -2em;
}

.chat-header {
  color: white;
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 25vw;
  background-color: rgba(0, 0, 0, 0.2);
}

.chat-window {
  background: rgba(0, 0, 0, 0.25);
  color: var(--secondary-color);
  margin-bottom: 1em;
  -webkit-backface-visibility: hidden;
  /* clip-path: polygon(0 100%, 0 10%, 40% 0, 60% 0, 100% 10%, 100% 100%); */
  width: 25vw;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  flex-direction: column-reverse;
  overflow: hidden;
  border: 1px solid #223;
  font-size: 0.95em;
  z-index: 3;
}
.mobile .chat-window {
  width: calc(100vw - 10px);
}
.chat-msgs {
  width: 100%;
  padding: 0 0.5em;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  flex-direction: column-reverse;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-wrap: break-word;
}

.chat-input {
  width: 100%;
  box-sizing: content-box;
  padding: 0;
  margin: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-color: var(--accent-color);
  color: var(--secondary-color);
  background-color: var(--primary-color);
}
.chat-input:focus {
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

import ChatMessage from "./ChatMessage.vue";
import ChatInput from "./ChatInput.vue";
import { ISignalMessage } from "@/views/Player/index.vue"

interface IUserChat {
  id: number;
  timestamp: number;
  username: string;
  msg: string;
  avatar?: string;
  pending?: boolean;
};

export default defineComponent({
  name: "Chat",
  components: {
    ChatMessage,
    ChatInput,
  },
  props: ["userCount"],
  data() {
    return {
      maxLength: 100,
      username: "",
      chats: Array<IUserChat>(),
    };
  },
  computed: {
    inputPlaceholder(): string {
      return this.username ? `how's the music, ${this.username}?` : "enter a username...";
    },
    chatsChronoSorted(): any[] {
      return [...this.chats].sort((a, b) => {
        return a.timestamp < b.timestamp
          ? 1
          : a.timestamp > b.timestamp
            ? -1
            : 0;
      });
    },
  },
  methods: {
    setUsername(val: any) {
      this.username = val.replace(/[^\w\s]/gi, '');
      localStorage.setItem('username', this.username);

      const welcomeMsg = {
        id: Date.now(),
        timestamp: Date.now(),
        username: '▂▃▅▇█ flowscape █▇▅▃▂',
        msg: `welcome to flowscape, "${this.username}"\n\nyou can change your username by typing:\n "/username <username>"\n`,
        avatar: "logo.png"
      };

      this.chats.push(welcomeMsg);
    },
    updateUsername(val: any) {
      this.username = val.replace(/[^\w\s]/gi, '');
      localStorage.setItem('username', this.username);

      const welcomeMsg = {
        id: Date.now(),
        timestamp: Date.now(),
        username: '▂▃▅▇█ flowscape █▇▅▃▂',
        msg: `your username is now "${this.username}"\n\n...they'll never even know ( ͡° ͜ʖ ͡°)\n`,
        avatar: "logo.png"
      };

      this.chats.push(welcomeMsg);
    },
    sendMsg(msg: any) {
      if (!this.username) {
        this.setUsername(msg);
        return;
      } else if (msg.includes("/username")) {
        this.updateUsername(msg.replace('/username ', ''));
        return;
      }
      if (this.chats.length > this.maxLength) {
        this.chats.shift();
      }
      this.chats.push({
        id: Date.now(),
        timestamp: Date.now(),
        msg: msg,
        username: this.username,
        pending: true
      });
      this.$emit("send", { username: this.username, message: msg });
    },
    recvMsg(msg: ISignalMessage) {
      // if msg recv'd is one of our pending messages, resolve
      let pend = this.chats.filter(cm => cm.pending).find(m => m.msg == msg.data);
      if (pend) pend.pending = false
      else {
        if (this.chats.length > this.maxLength) {
          this.chats.shift();
        }
        this.chats.push({
          id: Date.now(),
          timestamp: Date.now(),
          msg: msg.data,
          username: msg.user
        })
      }
    }
  },
  mounted() {
    let un = localStorage.getItem("username");
    if (un) this.setUsername(un);
  }
});
</script>
