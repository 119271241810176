<template>
  <g>
    <rect
      v-show="beatThreshold"
      class="beat-detect"
      :x="x"
      :width="width"
      :y="height - beatThreshold*height-4"
      :height="4"
    ></rect>
    <rect
      class="volume-meter"
      :x="x"
      :width="width"
      :y="height - volume*height"
      :height="volume*height"
    ></rect>
  </g>
</template>
<style scoped>
.beat-detect {
  fill: #ea00d9;
}

.volume-meter {
  fill: #0abdc6;
  opacity: 0.9;
}
</style>
<script>
export default {
  name: "Meter",
  props: ["x", "width", "maxHeight", "volume", "beatThreshold"],
  computed: {
    height() {
      return this.maxHeight || this.$parent.height;
    },
  },
};
</script>