<template>
  <div
    id="vinyl-viz"
    ref="wrapper"
  >
    <svg
      class="full-all vinyl-svg"
      :viewBox="`0 0 ${width} ${height}`"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs>
        <linearGradient
          id="fadeGrad"
          y2="1"
          x2="0"
        >
          <stop
            offset="0"
            :stop-color="$theme.secondaryColor"
            stop-opacity="1"
          />
          <stop
            offset=".6"
            :stop-color="$theme.secondaryColor"
            stop-opacity="0"
          />
        </linearGradient>
        <mask
          id="fade-donut"
          maskContentUnits="objectBoundingBox"
        >
          <rect
            width="1"
            height="1"
            fill="url(#fadeGrad)"
          />
          <circle
            :cx=".5"
            :cy=".5"
            :r=".49"
            fill="black"
            shape-rendering="geometricPrecision"
          ></circle>
        </mask>
        <mask
          id="fade"
          maskContentUnits="objectBoundingBox"
        >
          <rect
            width="1"
            height="1"
            fill="url(#fadeGrad)"
          />
        </mask>
        <filter
          id="noise"
          x="0%"
          y="0%"
          width="100%"
          height="100%"
        >
          <feTurbulence
            baseFrequency="0.01 0.4"
            result="NOISE"
            numOctaves="2"
          />
          <feDisplacementMap
            in="SourceGraphic"
            in2="NOISE"
            :scale="`${200 - 500*beatImpulse}`"
            xChannelSelector="R"
            yChannelSelector="R"
          ></feDisplacementMap>
        </filter>
        <mask
          id="vinyl-hole"
          maskContentUnits="objectBoundingBox"
        >
          <circle
            :cx=".5"
            :cy=".5"
            :r=".5"
            fill="url(#fadeGrad)"
          ></circle>
        </mask>
        <filter id="glow">
          <feGaussianBlur
            :stdDeviation="`${beatImpulse*5}`"
            result="coloredBlur"
          />
          <feMerge>
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <path
        id="curve"
        :d="`
          M ${cx - r/1.25}, ${cy}
          a ${r/1.25},${r/1.25} 0 1,1 ${r*2/1.25},0`"
        fill="transparent"
      ></path>
      <circle
        :cx="width/2"
        :cy="height/2"
        :r="r"
        :fill="$theme.secondaryColor"
        :stroke="$theme.secondaryColor"
        stroke-width="0"
        mask="url(#fade-donut)"
        shape-rendering="geometricPrecision"
      ></circle>
      <circle
        :cx="width/2"
        :cy="height/2"
        :r="height/5"
        :fill="$theme.secondaryColor"
        mask="url(#vinyl-hole)"
        filter="url(#noise)"
      ></circle>
      <text
        width="height/3"
        :fill="$theme.secondaryColor"
        class="vinyl-text"
        text-anchor="middle"
        filter="url(#glow)"
      >
        <textPath
          xlink:href="#curve"
          startOffset="50%"
        >{{song}}</textPath>
      </text>
      <text
        :x="width/2"
        :y="height/2"
        class="vinyl-text"
        :fill="$theme.secondaryColor"
        text-anchor="middle"
        filter="url(#glow)"
      >{{artist}}</text>
    </svg>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
// import { Curtains, Plane } from "curtainsjs";
export default defineComponent({
  props: ["apBus", "song", "artist"],
  data(): { width: number, height: number, beatImpulse: number } {
    return {
      // curtains: null,
      width: 500,
      height: 500,
      beatImpulse: 1.0
    };
  },
  computed: {
    cx(): number {
      return this.width / 2;
    },
    cy(): number {
      return this.height / 2;
    },
    r(): number {
      return this.height / 2;
    }
  },
  methods: {
    setSize() {
      this.width = (this.$refs.wrapper as HTMLElement).clientWidth;
      this.height = (this.$refs.wrapper as HTMLElement).clientHeight;
    }
  },
  mounted() {
    this.setSize();
    this.apBus.on("beat-detect", () => (this.beatImpulse = 1.));
    setInterval(() => {
      if (this.beatImpulse != 0) this.beatImpulse = Math.max(0, this.beatImpulse - (1 / 10) * 2);
    }, 100);
    // this.curtains = new Curtains({
    //   container: "vinyl-viz",
    // });
    // const vs = `
    //       precision mediump float;

    //       // those are the mandatory attributes that the lib sets
    //       attribute vec3 aVertexPosition;
    //       attribute vec2 aTextureCoord;

    //       // those are mandatory uniforms that the lib sets and that contain our model view and projection matrix
    //       uniform mat4 uMVMatrix;
    //       uniform mat4 uPMatrix;

    //       // our texture matrix that will handle image cover
    //       uniform mat4 uTextureMatrix0;

    //       // pass your vertex and texture coords to the fragment shader
    //       varying vec3 vVertexPosition;
    //       varying vec2 vTextureCoord;

    //       void main() {
    //         vec3 vertexPosition = aVertexPosition;
    //         gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);
    //         // set the varyings
    //         // here we use our texture matrix to calculate the accurate texture coords
    //         vTextureCoord = (uTextureMatrix0 * vec4(aTextureCoord, 0.0, 1.0)).xy;
    //         vVertexPosition = vertexPosition;
    //       }
    // `;
    // let fs = `
    //       precision mediump float;

    //       // get our varyings
    //       varying vec3 vVertexPosition;
    //       varying vec2 vTextureCoord;

    //       // the uniform we declared inside our javascript
    //       uniform float uTime;

    //       // our texture sampler (default name, to use a different name please refer to the documentation)
    //       uniform sampler2D uSampler0;

    //       void main() {
    //         // get our texture coords from our varying
    //         vec2 textureCoord = vTextureCoord;
    //         // displace our pixels along the X axis based on our time uniform
    //         // textures coords are ranging from 0.0 to 1.0 on both axis
    //         textureCoord.x += sin(textureCoord.y * 25.0) * cos(textureCoord.x * 25.0) * (cos(uTime / 50.0)) / 25.0;
    //         // map our texture with the texture matrix coords
    //         gl_FragColor = texture2D(uSampler0, textureCoord);
    //       }
    // `;
    // const params = {
    //   vertexShader: vs,
    //   fragmentShader: fs,
    //   uniforms: {
    //     time: {
    //       name: "uTime",
    //       type: "1f",
    //       value: 0,
    //     },
    //   },
    // };
    // const plane = new Plane(this.curtains, this.$refs.plane, params);
    // plane.onRender(() => {
    //   plane.uniforms.time.value++;
    // });

    // post process shader
    // const passParams = {
    //   vertexShaderID: "my-shader-pass-vs", // ID of your shader pass vertex shader script tag
    //   fragmentShaderID: "my-shader-pass-fs", // ID of your shader pass fragment shader script tag
    //   uniforms: { // uniforms are what will allow you to interact with your shader pass
    //     time: {
    //       name: "uTime", // uniform name that will be passed to our shaders
    //       type: "1f", // this means our uniform is a float
    //       value: 0, // initial value of the uniform
    //     },
    //   },
    // };
    // // add our shader pass using our curtains object and the parameters
    // const shaderPass = new ShaderPass(curtains, passParams);
    // shaderPass.onRender(function() {
    //   shaderPass.uniforms.time.value++; // update our time uniform value
    // });
  },
});
</script>
<style scoped>
@font-face {
  font-family: RoadRage;
  src: url("../../../assets/Road_Rage.otf");
}
#vinyl-viz {
  position: relative;
  height: 100%;
  width: 100%;
  color: var(--secondary-color);
}
.vinyl-svg {
  margin-top: 100px;
}
.vinyl-text {
  font-size: 2em;
  font-family: RoadRage;
}
</style>