
import { defineComponent } from "vue";
export default defineComponent({
  name: "PlayToggle",
  props: ["isActive"],
  data() {
    return {};
  },
  methods: {
    togglePlay(): void {
      this.$emit("toggle");
    },
  },
});
