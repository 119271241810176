<template>
  <div id="chat-msg">
    <div id="user-avatar">
      <img
        src="../../../../assets/gundamv2.png"
        :alt="username"
        crossorigin=""
      />
    </div>

    <div class="column">
      <span id="user-name">{{ username }}</span>
      <span id="user-msg">{{ msg }}</span>
    </div>
  </div>
</template>
<style scoped>
.column {
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  width: 100%;
  padding: 2px;
}
#chat-msg {
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1em;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  margin-top: 0.5em;
  white-space: break-spaces;
}
#chat-msg[data-pending="true"] {
  opacity: 0.5;
}
#chat-msg[data-self="true"] #user-name {
  color: var(--accent-color);
}
#user-avatar {
  height: calc(2em + 4px); /* add 2 * padding */
  width: calc(2em + 4px);
  min-height: calc(2em + 4px);
  min-width: calc(2em + 4px);
}
#user-avatar img {
  height: 100%;
  width: 100%;
}
#user-name {
  font-size: 1em;
  color: var(--secondary-color);
}
</style>
<script>
export default {
  props: {
    id: Number,
    msg: String,
    username: String,
    timestamp: String,
    avatar: String
  },
  data() {
    return {};
  }
};
</script>
